import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import AccountService from '../../services/account.service';
import ManagerService from '../../services/manager.service';


export const getBankList = createAsyncThunk(
  "/add_bank/get_banks",
  async(thunkAPI) => {
    try {
      const response = await AccountService.getBankList();
        // console.log(response.all_banks)
      return response.all_banks;
    } catch (error) {
      return error;
    }
  }
)

export const getCountryList = createAsyncThunk(
  "/add_bank/country_list",
  async(thunkAPI) => {
    try {
      const response = await AccountService.getCountries();
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

export const addBankDetails = createAsyncThunk(
  "/add_bank/",
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.addaccountDetails(payload);
      // console.log("add bank response")
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error;
    }
  }
)

export const bvnVeirifcation = createAsyncThunk(
  "/verify_bvn/",
  async({bvn, dob}, thunkAPI) => {
    try {
      const payload = {bvn, dob}
      const response = await AccountService.verifyBvn(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

// Get user profile details
export const contactlistUpload = createAsyncThunk(
  "/contact_upload/",
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.uploadContactList(payload);
      return response[0];
    } catch (error) {
      // console.log(error)
      return error
    }
  }
)

/********    ACCOUNT  */

export const validateAccountNumber = createAsyncThunk(
  "/add_bank/validation/",
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.validateAccountNumber(payload);
        // console.log("Account verification", response)
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const getAccounts = createAsyncThunk(
  "/virtual_account/",
  async(thunkAPI) => {
    try {
      const response = await AccountService.getVirtualAccounts();
      // console.log("accounts", response)
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

export const getMemberAccounts = createAsyncThunk(
  "/members/virtual_accounts/",
  async(thunkAPI) => {
    try {
      const response = await ManagerService.getVirtualAccounts();
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

export const createVirtualAccount = createAsyncThunk(
    '/virtual_account/',
    async(payload, thunkAPI) => {
        try {
            const response = await AccountService.createVirtualAccount(payload)
            if(response.success) {
              thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
            }
            else {
              thunkAPI.dispatch(setMessage({message: response.message, status: 'False'}))
            }
            return response
          } catch (error) {
            thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
            return error
        }
    }
)

/********    PAYCODES  */

export const createPaycode = createAsyncThunk(
  '/create_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.createPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const createQrPaycode = createAsyncThunk(
  '/create_paycode/',
  async(payload, thunkAPI) => {
      try {
        const response = await AccountService.createQrPaycode(payload)
        return response
      } catch (error) {
        return error
      }
  }
)

export const chargeBusinessRegistration = createAsyncThunk(
  '/create_paycode/',
  async(payload, thunkAPI) => {
      try {
        const response = await AccountService.chargeAccount(payload)
        return response
      } catch (error) {
        return error
      }
  }
)

export const createBulkPaycode = createAsyncThunk(
  '/create_bulk_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.bulkCreatePaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const applyPayCode = createAsyncThunk(
  '/paycode/redeem/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.claimPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const confirmPaycode = createAsyncThunk(
  '/confirm_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.confirmPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          // console.log(response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const claimPaycode = createAsyncThunk(
  '/claim_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.claimPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          // console.log(response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const claimPaycodeQr = createAsyncThunk(
  '/api-v1/claim_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.claimPaycodeWithQr(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          // console.log(response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const checkBalance = createAsyncThunk(
  '/paycode_balance/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.checkPaycodeBalance(payload)
          // thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          // console.log("PAYCODE", response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const getPaycodes = createAsyncThunk(
  '/paycodes/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.getMerchantPaycode(payload)
          // console.log("Paycodes", response)
          return response
      } catch (error) {
        return error
      }
  }
)

/********    TRANSACTIONS  */

export const getTransactionHistory = createAsyncThunk(
  '/transaction_history/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.transactionHistory(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getFasepayTransactionHistory = createAsyncThunk(
  '/transaction_history/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.fasepayTransactions(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getTransactionReports = createAsyncThunk(
  '/transaction_history/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.generateReport(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getTransferCharges = createAsyncThunk(
  '/transaction_charges/',
  async(thunkAPI) => {
      try {
        const response = await AccountService.getCharges()
        return response
      } catch (error) {
        return error
      }
  }
)

export const getAnalytics = createAsyncThunk(
  '/transaction_charges/',
  async(params, thunkAPI) => {
      try {
        const response = await AccountService.analytics(params)
        return response
      } catch (error) {
        return error
      }
  }
)

export const inflowAnalytics = createAsyncThunk(
  '/inflow_data/',
  async(_, thunkAPI) => {
      try {
        const response = await AccountService.getInflowData()
        return response
      } catch (error) {
        return error
      }
  }
)

/********    INVOICES  */

export const fetchInvoices = createAsyncThunk(
  '/merchant_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.getInvoices(payload)
      // console.log("Invoices", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getInvoice = createAsyncThunk(
  '/merchant_invoice/',
  async(reference, thunkAPI) => {
    try {
      const response = await AccountService.getInvoicesByRef(reference)
      return response
    }
    catch (error){ 
      return error
    }
})

export const addInvoice = createAsyncThunk(
  '/merchant_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.createInvoice(payload)
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
  })

export const payInvoice = createAsyncThunk(
  '/pay_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.invoicePayment(payload)
      return response
    }
    catch (error){ 
      return error
    }
  })

export const invoiceVerification = createAsyncThunk(
  '/verify_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.verifyInvoice(payload)
      return response
    }
    catch (error){ 
      return error
    }
  })

export const getAnnouncements = createAsyncThunk(
  '/announcements/',
  async(thunkAPI) => {
    try {
      const response = await AccountService.announcements()
      // console.log("Verify", response)
      return response
    }
    catch (error){ 
      return error
    }
  })
  
  /****** VOUCHERS */
  
  export const getUserVouchers = createAsyncThunk(
    '/merchant_invoice/',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.getVouchers(payload)
        // console.log("Vouchers", response)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })


  /****** FasePay */

  export const payWithFase = createAsyncThunk(
    '/fase_pay/',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.fasePay(payload)
        // thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  export const fxExchange = createAsyncThunk(
    '/fase_pay/',
    async(payload) => {
      try {
        const response = await AccountService.sellFx(payload)
        return response
      }
      catch (error){ 
        return error
      }
  })

  export const getRates = createAsyncThunk(
    '/sell_fx/',
    async(_,) => {
      try {
        const response = await AccountService.getFxRates()
        return response
      }
      catch (error){ 
        return error
      }
  })

  /******** LOYALTY VOUCHERS  */

export const createLoyaltyPaycode = createAsyncThunk(
  '/create_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.createLoyaltyPaycode(payload)
          return response
      } catch (error) {
        return error
      }
  }
)

export const applyLoyaltyPayCode = createAsyncThunk(
  '/paycode/redeem/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.claimLoyaltyPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const confirmLoyaltyPaycode = createAsyncThunk(
  '/confirm_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.confirmLoyaltyPaycode(payload)
          // console.log(response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const claimLoyaltyPaycode = createAsyncThunk(
  '/claim_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.claimLoyaltyPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          // console.log(response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const checkLoyaltyBalance = createAsyncThunk(
  '/paycode_balance/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.checkLoyaltyPaycodeBalance(payload)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const getLoyaltyPaycodes = createAsyncThunk(
  '/paycodes/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.getLoyaltyPaycodes(payload)
          return response
      } catch (error) {
        return error
      }
  }
)

export const getLoyaltyPaycodeHistory = createAsyncThunk(
  '/paycodes/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.getLoyaltyPaycodeHistory(payload)
          return response
      } catch (error) {
        return error
      }
  }
)

export const getTaxCalculations = createAsyncThunk(
  '/paycodes/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.getTaxCalculators(payload)
          return response
      } catch (error) {
        return error
      }
  }
)

export const getOTP = createAsyncThunk(
  '/loyalty_voucher_verification/',
  async(user_type, thunkAPI) => {
      try {
          const response = await AccountService.sendOtp(user_type)
          return response
      } catch (error) {
        return error
      }
  }
)

export const getVoucherDiscount = createAsyncThunk(
  '/loyalty_voucher_verification/',
  async(_, thunkAPI) => {
      try {
          const response = await AccountService.getVoucherDiscount()
          return response
      } catch (error) {
        return error
      }
  }
)

export const configureVoucherDiscount = createAsyncThunk(
  '/loyalty_voucher_verification/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.setVoucherDiscount(payload)
          return response
      } catch (error) {
        return error
      }
  }
)


const accountSlice = createSlice({
  name: "account",
  initialState: {
    is_account_created: false,
    accounts: null
  },
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
    setUser(state, action) {
      state.isLoading = false
      state.user = action.payload
    },
    setToken(state, action) {
      localStorage.setItem("token", action.payload); 
    },
    signOut(state) {
      state.isLoggedIn = false
      state.user = null
    },

  },
  extraReducers: {
    [createVirtualAccount.fulfilled]: (state, action) => {
    },
    [getAccounts.fulfilled]: (state, action) => {
      if(typeof(action.payload) === 'object')
      state.accounts = action.payload
    },
    [getMemberAccounts.fulfilled]: (state, action) => {
      if(typeof(action.payload) === 'object')
      state.accounts = action.payload
    },
  }
})

const { reducer } = accountSlice
export default reducer;