import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
// import avatar from '../../assets/images/avatar.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import { clearMessage } from "../../redux/slices/message";
import { useDispatch, useSelector } from "react-redux";
import { applyPayCode, getPaycodes, checkBalance, createBulkPaycode } from "../../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import CryptoJS from "crypto-js";
import { verifyWalletPin } from "../../redux/slices/users";
import dateFormatter from "../../utils/dateFormatter";
import RecordFilter from "../../components/RecordFilter";

const Paycode = () => {

    const dispatch = useDispatch();
    const { user, isLoggedin } = useSelector((state) => state.auth)
    const { message } = useSelector((state) => state.message)

    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [showBalance, setShowBalance] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
    const [transaction, setTransaction] = useState({})
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [genVoucher, setGenVoucher] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [errors, setErrors] = useState({})
    const [analytics, setAnalytics] = useState({})
    const [decrypted, setDecrypted] = useState(null)
    const [paycode, setPaycode] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [page, setPage] = useState(1)

    const secretPass = 'ZphGxxfW2t2q'

    useEffect(() => {
        if (message && message.status === 'False') {
            Swal.fire({ 
                icon: 'error',
                text: message.message
            })
        }
        else if (message && message.status === 'True') {
            Swal.fire({
                icon: 'success',
                title: message.message
            })
            .then(() => setGenVoucher(false))
        }

        dispatch(clearMessage())

        loadPaycodes()

    }, [message, dispatch])

    useEffect(() => {
        loadPaycodes()
    }, [payload.page])

    const handleEncryption = (txt) => {
        return CryptoJS.AES.encrypt(JSON.stringify(txt), secretPass).toString()
    }

    const handleDecryption = (txt) => {
        const bytes = CryptoJS.AES.decrypt(txt, secretPass);
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log(data)
        return data
    };

    const selectPaycode = (item) => {
        setPaycode(item)
       let encrypted = handleEncryption(item?.secret)
       setDecrypted(encrypted)
        setShow(true)
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        // else if(name === 'email' && !pattern.test(value)) {
        //     setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        // }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }


    const createPayCode = () => {
        setLoading(true)
        dispatch(createBulkPaycode(payload))
        .then((e) => {
            // console.log("PAYCODE RES", e)
            loadPaycodes(page)
        })
        .finally(() => setLoading(false))
    }

    const applyPaycode = () => {
        if(!payload.amount && !payload.pin && !payload.secret)  { return }

        setLoading(true)
        dispatch(applyPayCode(payload))
        .then((response) => {
            setLoading(false)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => setForm(false))
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    const paycodeBalance = () => {
        if(!payload.paycode && !payload.secret_word)  {  return }
        setLoading(true)
        dispatch(checkBalance(payload))
        .then((response) => {
            if(response.payload?.success) {
                Swal.fire({
                    icon: 'info',
                    text: response.payload?.message
                })
                .then(() => setShowBalance(false))
            }
        })
        .finally(() => setLoading(false))
    }

    const loadPaycodes = () => {
        setLoading(true)
        dispatch(getPaycodes(payload))
        .then((response) => {
            if(response.payload?.results) {
                setPaycodes(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const verifyPin = () => {
        setLoading(true)
        dispatch(verifyWalletPin(payload))
        .then((response) => {
            if(response.payload?.success === true) {
                // console.log(paycode)
                setDecrypted(paycode?.secret)
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }
    
    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleSearch = () => {
        loadPaycodes()
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                        <h3 className="m-0">PayCode Transactions</h3>
                        
                        <p className="m-0 text-primary font-bold">Paycode Value: <span className="fs-4">{ formatCurrency(analytics?.total_paycode_value || 0).with_currency }</span></p>
                    </div>
                    <div>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="text-primary bg-white border-0 mb-2"
                            onClick={ () => setShowBalance(true)}
                        >
                            <span className="ps-2">Check balance</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="text-primary bg-white border-0 mx-2 mb-2"
                            onClick={ () => setGenVoucher(true)}
                        >
                            <span className="ps-2">Generate eVoucher</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="text-primary bg-white border-0 mb-2"
                            onClick={ () => setForm(true)}
                        >
                            <span className="ps-2">Apply PayCode</span>
                        </Button>
                    </div>
                </Col>

                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Row className="data-card mb-4">
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--light">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#4F4F4F"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#4F4F4F"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#4F4F4F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#4F4F4F"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.all_paycodes || '0'}</h5>
                                        <p className="m-0">All Paycodes generated</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--success">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#008000"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#008000"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#008000"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#008000"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.all_active_paycode || '0'}</h5>
                                        <p className="m-0">All Active PayCodes</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--warning">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#F2994A"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#F2994A"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#F2994A"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#F2994A"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.all_used_paycode || '0'}</h5>
                                        <p className="m-0">Used PayCodes</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--primary">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <rect width="20" height="20" transform="translate(0 0.5)" fill="#FFF2F8"/>
                                            <path d="M11.25 8.62492C11.25 8.27974 10.9702 7.99992 10.625 7.99992H5.625C5.27982 7.99992 5 8.27974 5 8.62492C5 8.9701 5.27982 9.24992 5.625 9.24992H10.625C10.9702 9.24992 11.25 8.9701 11.25 8.62492Z" fill="#EF5DA8"/>
                                            <path d="M10.4167 11.1249C10.4167 10.7797 10.1368 10.4999 9.79167 10.4999H5.625C5.27982 10.4999 5 10.7797 5 11.1249C5 11.4701 5.27982 11.7499 5.625 11.7499H9.79167C10.1368 11.7499 10.4167 11.4701 10.4167 11.1249Z" fill="#EF5DA8"/>
                                            <path d="M10.625 12.9999C10.9702 12.9999 11.25 13.2797 11.25 13.6249C11.25 13.9701 10.9702 14.2499 10.625 14.2499H5.625C5.27982 14.2499 5 13.9701 5 13.6249C5 13.2797 5.27982 12.9999 5.625 12.9999H10.625Z" fill="#EF5DA8"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6249H15.8333C17.099 18.6249 18.125 17.5989 18.125 16.3333V11.7499C18.125 11.4047 17.8452 11.1249 17.5 11.1249H14.7917V4.61925C14.7917 3.43308 13.4509 2.74311 12.4857 3.43256L12.3398 3.53675C11.6894 4.00133 10.8084 3.99977 10.1549 3.53301C9.06839 2.7569 7.59828 2.7569 6.51173 3.53301C5.85826 3.99977 4.97725 4.00133 4.32685 3.53675L4.18097 3.43256C3.21575 2.74311 1.875 3.43308 1.875 4.61925V15.4999C1.875 17.2258 3.27411 18.6249 5 18.6249ZM7.23827 4.55018C7.89021 4.08451 8.77646 4.08451 9.42839 4.55018C10.5134 5.3252 11.9783 5.33112 13.0664 4.55392L13.2122 4.44972C13.3501 4.35123 13.5417 4.4498 13.5417 4.61925V16.3333C13.5417 16.7084 13.6318 17.0624 13.7915 17.3749H5C3.96447 17.3749 3.125 16.5355 3.125 15.4999V4.61925C3.125 4.4498 3.31654 4.35123 3.45442 4.44972L3.6003 4.55392C4.68838 5.33112 6.15325 5.3252 7.23827 4.55018ZM14.7917 16.3333V12.3749H16.875V16.3333C16.875 16.9086 16.4086 17.3749 15.8333 17.3749C15.258 17.3749 14.7917 16.9086 14.7917 16.3333Z" fill="#EF5DA8"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.expired_paycode || '0'}</h5>
                                        <p className="m-0">Expired PayCode</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                    />

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>PayCode ID</th>
                                        <th>Beneficiary</th>
                                        <th>Amt Remaining</th>
                                        <th>Amt Used</th>
                                        <th>Date Created</th>
                                        <th>Token</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paycodes?.length ? (
                                            paycodes.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.reference}
                                                        <button
                                                            style={{border: "none", display: "block", backgroundColor: "transparent"}}
                                                            className="text-primary"
                                                            onClick={ () => selectPaycode(item) }
                                                        >
                                                            Reveal Secret Word
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <p className="m-0 d-flex gap-2 align-items-center">
                                                            <img src={item.avatar || 'https://via.placeholder.com/50'} alt="avatar" width="30" className="rounded-circle"/>
                                                            <strong>
                                                                {item.owner || `${user?.first_name+' '+user?.last_name}`}
                                                            </strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.used_amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.date)}
                                                    </td>
                                                    <td>
                                                        {item.token}
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.valid_detail ? 'badge-active' : 
                                                                (item.used === 'used' ? 'badge-used' : 
                                                                (!item.valid_detail ? 'badge-failed' : 'badge-pending')
                                                                )}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.valid_detail ? "#008000" : 
                                                                        (item.used ? "#828282" : "#FAB941")
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.valid_detail ? 'Active' : (item.used ? 'Used' : 'Expired')}
                                                                </span>
                                                            </Badge> 
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        paycodes?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>

                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Voucher Balance</small>
                                                {transaction.amount} 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Valid Till</small>
                                            23-08-2023
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>

                <DrewsModal
                    show={showBalance}
                    onHide={() => setShowBalance(false)}
                    size="md"
                    dismissable={true}
                    title="Voucher Balance"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Voucher's Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="paycode"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['paycode'] && 
                                    <Form.Text className="text-danger">{errors['paycode']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret_word'] && 
                                    <Form.Text className="text-danger">{errors['secret_word']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={paycodeBalance}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Check Balance <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={genVoucher}
                    onHide={() => setGenVoucher(false)}
                    size="md"
                    dismissable={true}
                    title="Generate PayCode"
                >
                    <Form>
                        <p className="text-sm">Generate new paycode.</p>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0.00"
                                    name="amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Quantity</Form.Label>
                                <Form.Control
                                    type="number"
                                    required
                                    placeholder="e.g: 1"
                                    name="qty"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    min={1}
                                />
                                {
                                    errors['qty'] && 
                                    <Form.Text className="text-danger">{errors['qty']}</Form.Text>
                                }
                            </Form.Group>
                            
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret'] && 
                                    <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={createPayCode}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Generate PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title="Apply PayCode"
                >
                    <Form>
                        <p className="text-sm">Apply Paycode.</p>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">PayCode</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="pin"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['pin'] && 
                                    <Form.Text className="text-danger">{errors['pin']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret'] && 
                                    <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0.00"
                                    name="amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={applyPaycode}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Apply PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={show}
                    onHide={() => setShow(false)}
                    size="md"
                    dismissable={true}
                    title="Reveal Paycode Secret"
                >
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <Form.Group as={Col} md={12} lg={12} sm={12} className="mb-2">
                                <Form.Label className="mb-0">Secret Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={ decrypted }
                                    name="secret"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} lg={12} sm={12} className="mb-2">
                                <Form.Label className="mb-0">Wallet PIN</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="wallet_pin"
                                    required
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    placeholder="Enter your wallet PIN to reveal paycode sceret"
                                    maxLength={4}
                                    minLength={4}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} lg={12} sm={12} className="mb-2">
                                <Button 
                                    variant="primary"
                                    type="button"
                                    className="form-control"
                                    onClick={verifyPin}
                                >
                                    Reveal  <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default Paycode;