import { Col, Container, Form, Row, Button, Card, Spinner, FormGroup } from "react-bootstrap";
import Logo from "../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { applyLoyaltyPayCode, claimPaycodeQr, createQrPaycode, payInvoice } from "../redux/slices/account.service";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import formatCurrency from "../utils/currencyFormmter";
import axios from "axios";

const QrPayment = () => {

    const currencies = [
        { id: 1, name: "NGN", code: "NGN", symbol: "₦" },
        { id: 2, name: "USD", code: "USD", symbol: "$" },
        { id: 3, name: "GBP", code: "GBP", symbol: "£" },
        { id: 4, name: "CAD", code: "CAD", symbol: "$" },
        // { id: 3, name: "EUR", code: "eur", symbol: "€" },
    ];

    const conf = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_PK,
        tx_ref: Date.now(),
        amount: 0,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: '',
          phone_number: '',
          name: '',
        },
        customizations: {
          title: 'WallX Business Paycode Payment',
          description: 'Funding the paycode you generated',
          logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
    }
    
    const [method, setMethod] = useState(null)
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({})
    const [merchant, setMerchant] = useState({})
    const [errors, setErrors] = useState({})
    const [config, setConfig] = useState(conf)
    const { id } = useParams()

    
    const dispatch = useDispatch();
    const isLoaded = useRef(false)
    
    useEffect(() => {
        if(id && !isLoaded.current) {
            isLoaded.current = true
            getMerchant(id)
        }
    }, []) 


    const handleSelection = (name) => {
        setMethod(name)
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const payWithPaycode = () => {
        if (!payload.pin && !payload.amount && !payload.secret) return;
        setLoading(true)

        let request_payload = payload
        request_payload.amount = payload.amount
        // request_payload.pay_mode = 'paycode'
        request_payload.merchant_id = id

        // dispatch(claimPaycodeQr(request_payload))
        axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/claim_paycode/`, request_payload)
        .then((res) => {
            // console.log("response", res)

            if(res.data?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.data?.message
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.data?.message
                })
            }
        })
        .catch((error) => {
            Swal.fire({
                icon: 'error',
                text: error.response?.data?.message
            })
        })
        .finally(() => setLoading(false))
    }

    const getMerchant = (merchantId) => {
        // console.log(merchantId)
        setLoading(true)
        axios.get(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/merchants/?merchant_id=${merchantId}`)
        .then((response) => {
            if(response.data.length > 0) {
                setMerchant(response.data[0])
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'Could not fetch merchant profile'
                })
            }
        })
        .catch((error) => {
            Swal.fire({
                icon: 'error',
                text: error.response?.data?.message
            })
        })
        .finally(() => setLoading(false))
    }

    const createNewPaycode = () => {
        let new_config = config

        new_config.customer.email = payload.email
        new_config.customer.phone_number = payload.mobile
        new_config.amount = payload.amount
        
        setConfig(new_config)
        
        Swal.fire({
            title: "Confirm your action",
            html: `Creating payment code for ${merchant?.store_name}`,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: 'Yes, Proceed',
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'No, Cancel',
        })
        .then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let request_payload = payload
                request_payload.merchant_id = id
                setLoading(true)
                dispatch(createQrPaycode(request_payload))
                .then((response) => {
                    if(response.payload?.success === true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.payload?.message,
                            showConfirmButton: true, 
                            confirmButtonText: 'Pay now',
                            allowOutsideClick: false
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                window.open(response.payload?.link, '_blank')
                            }
                        })
                    }
                    if(response.payload?.success === 'False') {
                        Swal.fire({
                            icon: 'error',
                            text: response.payload?.message
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }

    const payWithLoyaltyVoucher = () => {
        if(!payload.amount && !payload.code && !payload.secret)  { return }
        let request_payload = payload
        request_payload.currency = 'NGN'
        request_payload.amount = payload.amount
        request_payload.merchant_id = id

        setLoading(true)
        // dispatch(claimPaycodeQr(payload))
        axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/claim_paycode/`, request_payload)
        .then((response) => {
            // console.log("response", response)
            if(response.data?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.data?.message
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.data?.message
                })
            }
        })
        .catch((error) => {
            Swal.fire({
                icon: 'error',
                text: error.response?.data?.message
            })
        })
        .finally(() => setLoading(false))
    }
    
    return (
        <Container className="full-height mt-3">
            <Row className="justify-content-center">
                <div className="mt-31 mb-1 text-center">
                    <Logo variant="dark"/>
                </div>
                {
                    loading ? (
                        <div className="page-loader">
                            <Spinner size="lg" variant="primary"/>
                        </div>
                    ) : null
                }
                <Col md={5} lg={5} sm={12} xs={12} className={`rounded-3 p-4 ${ method === null ? "border border-1" : null}`}>
                    <Row className="justify-content-center">
                        <Col md={12}>
                                <h6 className="text-muted fw-bold fs-4 mb-1">Paying to {merchant?.store_name}</h6>
                                <p>This page allows you pay to the above merchant.</p>
                                {
                                    method === null ? (
                                        <Card>
                                            <CardHeader className="text-primary font-bold bg-transparent">
                                                Choose an option below.
                                            </CardHeader>
                                            <Card.Body className="p-0">
                                                <Col md={12} className="p-0">
                                                    <button 
                                                        className="p-3 btn-plain w-100 d-flex justify-content-between align-items-center" 
                                                        onClick={() => handleSelection('paycode')}
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        <div>
                                                            <p className="font-bold m-0">
                                                                <FontAwesomeIcon icon="credit-card" className="me-2 text-muted"/>
                                                                I HAVE MY WALLX PAYCODE
                                                            </p>
                                                            <p className="m-0 text-sm text-muted ms-4">Kindly use your generated Wallx Pin to pay faster.</p>
                                                        </div>
                                                        <FontAwesomeIcon icon="chevron-right" className="justify-self-end"/>
                                                    </button>
                                                    <hr />
                                                </Col>
                                                <Col md={12} className="p-0">
                                                    <button 
                                                        className="p-3 btn-plain w-100 d-flex justify-content-between align-items-center" 
                                                        onClick={() => handleSelection('loyalty_paycode')}
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        <div>
                                                            <p className="font-bold m-0">
                                                                <FontAwesomeIcon icon="gifts" className="me-2 text-muted"/>
                                                                I HAVE LOYALTY PAYCODE
                                                            </p>
                                                            <p className="m-0 text-sm text-muted ms-4">Kindly use your Loyalty PayCode to pay faster.</p>
                                                        </div>
                                                        <FontAwesomeIcon icon="chevron-right" className="justify-self-end"/>
                                                    </button>
                                                    <hr />
                                                </Col>
                                                <Col md={12} className="p-0">
                                                    <button 
                                                        className="p-3 btn-plain w-100 d-flex justify-content-between align-items-center" 
                                                        onClick={() => handleSelection('new_paycode')}
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        <div>
                                                            <p className="font-bold m-0">
                                                                <FontAwesomeIcon icon="money-bill-1-wave" className="me-2 text-muted"/>
                                                                I DON'T HAVE MY WALLX PAYCODE
                                                            </p>
                                                            <p className="m-0 text-sm text-muted ms-4">Click to generate a paycode immediately.</p>
                                                        </div>
                                                        <FontAwesomeIcon icon="chevron-right" className="justify-self-end"/>
                                                    </button>
                                                    <hr />
                                                </Col>
                                                <Col md={12} className="p-0">
                                                    <button
                                                        type="button" 
                                                        className="p-3 btn-plain w-100 d-flex justify-content-between align-items-center text-dark" 
                                                        style={{ textAlign: "left" }}
                                                        onClick={() => window.open("https://play.google.com/store/apps/details?id=co.wallx.android", "_blank")}
                                                    >
                                                        <div>
                                                            <p className="font-bold m-0">
                                                                <FontAwesomeIcon icon="mobile-android-alt" className="me-2 text-muted"/>
                                                                DOWNLOAD OUR MOBILE APP
                                                            </p>
                                                            <p className="m-0 text-sm text-muted ms-4">
                                                            <a href="https://play.google.com/store/apps/details?id=co.wallx.android" target="_blank" className="text-primary">Download</a>  our app to enjoy more benefits and pay faster than others.
                                                            </p>
                                                        </div>
                                                        <FontAwesomeIcon icon="chevron-right" className="justify-self-end"/>
                                                    </button>
                                                    {/* <hr /> */}
                                                </Col>
                                            </Card.Body>
                                        </Card>
                                    ) : 
                                    <Card>
                                        <Card.Body>
                                            <Row className="p-3 pt-0">
                                                <Col md={12}>
                                                    {
                                                        method === 'new_paycode' ? (
                                                            <Form>
                                                                <p className="font-bold">Generate new paycode.</p>
                                                                <Row>
                                                                    <Form.Group as={Col} md={12} className="mb-2">
                                                                        <Form.Label className="mb-0">Full Name</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            required
                                                                            placeholder="Full Name"
                                                                            name="full_name"
                                                                            onChange={handleInput}
                                                                            onKeyUp={handleValidation}
                                                                        />
                                                                        {
                                                                            errors['full_name'] && 
                                                                            <Form.Text className="text-danger">{errors['full_name']}</Form.Text>
                                                                        }
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} className="mb-2">
                                                                        <Form.Label className="mb-0">Mobile Number</Form.Label>
                                                                        <Form.Control
                                                                            type="tel"
                                                                            required
                                                                            placeholder="Mobile number"
                                                                            name="mobile"
                                                                            onChange={handleInput}
                                                                            onKeyUp={handleValidation}
                                                                            maxLength={11}
                                                                            minLength={11}
                                                                        />
                                                                        {
                                                                            errors['mobile'] && 
                                                                            <Form.Text className="text-danger">{errors['mobile']}</Form.Text>
                                                                        }
                                                                    </Form.Group>
                                                                    
                                                                    <Form.Group as={Col} md={12} className="mb-2">
                                                                        <Form.Label className="mb-0">Email</Form.Label>
                                                                        <Form.Control
                                                                            type="email"
                                                                            required
                                                                            placeholder="Email"
                                                                            name="email"
                                                                            onChange={handleInput}
                                                                            onKeyUp={handleValidation}
                                                                        />
                                                                        {
                                                                            errors['email'] && 
                                                                            <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                                                        }
                                                                    </Form.Group>
                                    
                                    
                                                                    <Form.Group as={Col} md={12} className="mb-2">
                                                                        <Form.Label className="mb-0">Amount</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            required
                                                                            placeholder="0.00"
                                                                            name="amount"
                                                                            onChange={handleInput}
                                                                            onKeyUp={handleValidation}
                                                                            value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                                                        />
                                                                        {
                                                                            errors['amount'] && 
                                                                            <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                                                        }
                                                                    </Form.Group>
                                                                    
                                                                    <Form.Group as={Col} md={12} className="mb-2">
                                                                        <Form.Label className="mb-0">Secret Word</Form.Label>
                                                                        <Form.Control
                                                                            type="password"
                                                                            required
                                                                            placeholder="Secret Word"
                                                                            name="secret"
                                                                            onChange={handleInput}
                                                                            onKeyUp={handleValidation}
                                                                            maxLength={6}
                                                                            minLength={6}
                                                                        />
                                                                        {
                                                                            errors['secret'] && 
                                                                            <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                                                        }
                                                                    </Form.Group>
                                    
                                                                    <Form.Group 
                                                                        as={Col} 
                                                                        md={12} 
                                                                        className="mt-4 d-grid gap-2"
                                                                    >
                                                                        <Button 
                                                                            variant="primary" 
                                                                            onClick={createNewPaycode}
                                                                            disabled={Object.keys(errors).length > 0}
                                                                        >
                                                                            Generate PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                        </Button>
                                                                        <Button
                                                                            type="button" 
                                                                            variant="primary"
                                                                            onClick={() => handleSelection(null)}
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Row>
                                                            </Form> 
                                                        )
                                                        : 
                                                        method === 'paycode' ? (
                                                            <Row>
                                                                <FormGroup as={Col} md={12} className="mb-2">
                                                                    <Form.Label>Currency</Form.Label>
                                                                    <Form.Select
                                                                        required
                                                                        name="currency"
                                                                        onChange={handleInput}
                                                                        onBlur={handleValidation}
                                                                    >
                                                                        <option value="">Select Currency</option>
                                                                        {currencies.map((currency, index) => (
                                                                            <option key={index} value={currency.code}>{currency.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    {
                                                                        errors['currency'] && 
                                                                        <Form.Text className="text-danger">{errors['currency']}</Form.Text>
                                                                    }
                                                                </FormGroup>
                                                                <FormGroup as={Col} md={12} className="mb-2">
                                                                    <Form.Label>Amount</Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        placeholder="0.00"
                                                                        name="amount"
                                                                        onChange={handleInput}
                                                                        onKeyUp={handleValidation}
                                                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}

                                                                    />
                                                                    {
                                                                        errors['amount'] && 
                                                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                                                    }
                                                                </FormGroup>
                                                                <FormGroup as={Col} md={12} className="mb-2">
                                                                    <Form.Label>PayCode</Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        placeholder="paycode token"
                                                                        name="pin"
                                                                        onChange={handleInput}
                                                                        onKeyUp={handleValidation}
                                                                        value={payload?.pin || ''}
                                                                        maxLength={6}
                                                                        minLength={6}
                                                                    />
                                                                    {
                                                                        errors['pin'] && 
                                                                        <Form.Text className="text-danger">{errors['pin']}</Form.Text>
                                                                    }
                                                                </FormGroup>
                                                                <FormGroup as={Col} md={12} className="mb-2">
                                                                    <Form.Label>Secret</Form.Label>
                                                                    <Form.Control
                                                                        type="password"
                                                                        required
                                                                        placeholder="Secret"
                                                                        name="secret"
                                                                        onChange={handleInput}
                                                                        // maxLength={6}
                                                                        // minLength={4}
                                                                        onKeyUp={handleValidation}
                                                                    />
                                                                    {
                                                                        errors['secret'] && 
                                                                        <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                                                    }
                                                                </FormGroup>
                                                                <div className="d-grid gap-2 mt-2">
                                                                    <Button
                                                                        type="button" 
                                                                        variant="primary"
                                                                        onClick={payWithPaycode}
                                                                        disabled={Object.keys(errors).length > 0}
                                                                    >
                                                                        Proceed  <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                    </Button>
                                                                    <Button
                                                                        type="button" 
                                                                        variant="primary"
                                                                        onClick={() => handleSelection(null)}
                                                                    >
                                                                        Back
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        )
                                                        :
                                                        method === 'loyalty_paycode' ? (
                                                            <Row>
                                                                <FormGroup as={Col} md={12} className="mb-2">
                                                                    <Form.Label>Loyalty PayCode</Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        placeholder="Voucher code"
                                                                        name="pin"
                                                                        onChange={handleInput}
                                                                        onKeyUp={handleValidation}
                                                                        value={payload?.pin || ''}
                                                                        maxLength={6}
                                                                        minLength={6}
                                                                    />
                                                                    {
                                                                        errors['pin'] && 
                                                                        <Form.Text className="text-danger">{errors['pin']}</Form.Text>
                                                                    }
                                                                </FormGroup>
                                                                <FormGroup as={Col} md={12} className="mb-2">
                                                                    <Form.Label>Amount</Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        placeholder="0.00"
                                                                        name="amount"
                                                                        onChange={handleInput}
                                                                        onKeyUp={handleValidation}
                                                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}

                                                                    />
                                                                    {
                                                                        errors['amount'] && 
                                                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                                                    }
                                                                </FormGroup>
                                                                <FormGroup as={Col} md={12} className="mb-2">
                                                                    <Form.Label>Secret</Form.Label>
                                                                    <Form.Control
                                                                        type="password"
                                                                        required
                                                                        placeholder="Secret"
                                                                        name="secret"
                                                                        onChange={handleInput}
                                                                        // maxLength={6}
                                                                        // minLength={4}
                                                                        onKeyUp={handleValidation}
                                                                    />
                                                                    {
                                                                        errors['secret'] && 
                                                                        <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                                                    }
                                                                </FormGroup>
                                                                <div className="d-grid gap-2 mt-2">
                                                                    <Button
                                                                        type="button" 
                                                                        variant="primary"
                                                                        onClick={payWithLoyaltyVoucher}
                                                                        disabled={Object.keys(errors).length > 0}
                                                                    >
                                                                        Proceed  <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                    </Button>
                                                                    <Button
                                                                        type="button" 
                                                                        variant="primary"
                                                                        onClick={() => handleSelection(null)}
                                                                    >
                                                                        Back
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        )
                                                        : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}


export default QrPayment;